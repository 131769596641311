export const CiscoIcons = [
  '100baset-hub',
  '10700',
  '10ge-fcoe',
  '15200',
  '3x74-floor-cluster-control',
  '6700-series',
  '7500ars7513',
  'accesspoint',
  'ace',
  'acs',
  'adm',
  'androgenous-person',
  'antenna',
  'asa-5500',
  'asic-processor',
  'asr-1000-series',
  'ata',
  'atm-3800',
  'atm-fast-gigabit',
  'atm-router',
  'atm-switch',
  'atm-tag-switch-router',
  'avs',
  'axp',
  'bbsm',
  'branch-office',
  'breakout-box',
  'bridge',
  'broadcast-router',
  'bts-10200',
  'cable-modem',
  'callmanager',
  'car',
  'carrier-routing-system',
  'cddi-fddi',
  'cdm',
  'cell-phone',
  'centri-firewall',
  'cisco-1000',
  'cisco-ca',
  'cisco-file-engine',
  'cisco-hub',
  'cisco-security',
  'cisco-unified-presence-server',
  'cisco-unity-express',
  'cisco-works',
  'class-4-5-switch',
  'cloud',
  'communications-server',
  'congtent-switch-module',
  'contact-center',
  'content-acquirer',
  'content-engine-cache-director',
  'content-service-router',
  'content-service-switch-1100',
  'content-switch',
  'content-transformation-engine-',
  'cs-mars',
  'csm-s',
  'csu-dsu',
  'cube',
  'detector',
  'director-class-fibre-channel-di',
  'directory-server',
  'diskette',
  'distributed-director',
  'dot-dot',
  'dpt',
  'dslam',
  'dual-mode',
  'dwdm-filter',
  'end-office',
  'fax',
  'fc-storage',
  'fddi-ring',
  'fibre-channel-disk-subsystem',
  'fibre-channel-fabric-switch',
  'file-cabinet',
  'file-server',
  'fileserver',
  'firewall-service-module-fwsm',
  'firewall',
  'front-end-processor',
  'gatekeeper',
  'general-appliance',
  'generic-building',
  'generic-gateway',
  'generic-processor',
  'generic-softswitch',
  'gigabit-switch-atm-tag-router',
  'government-building',
  'ground-terminal',
  'guard',
  'h323',
  'handheld',
  'hootphone',
  'host',
  'hp-mini',
  'hub',
  'iad-router',
  'ibm-mainframe',
  'ibm-mini-as400',
  'ibm-tower',
  'icm',
  'ics',
  'intelliswitch-stack',
  'internet-streamer',
  'ios-firewall',
  'ios-slb',
  'ip-communicator',
  'ip-dsl',
  'ip-phone',
  'ip-telephony-router',
  'ip',
  'iptc',
  'iptv-content-manager',
  'iptv-server',
  'iscsi-router',
  'isdn-switch',
  'itp',
  'jbod',
  'key',
  'keys',
  'lan-to-lan',
  'laptop',
  'layer-2-remote-switch',
  'layer-3-switch',
  'lightweight-ap',
  'localdirector',
  'lock',
  'longreach-cpe',
  'mac-woman',
  'macintosh',
  'man-woman',
  'mas-gateway',
  'mau',
  'mcu',
  'mdu',
  'me1100',
  'mediator',
  'meetingplace',
  'mesh-ap',
  'metro-1500',
  'mgx-8000-multiservice-switch',
  'microphone',
  'microwebserver',
  'mini-vax',
  'mobile-access-ip-phone',
  'mobile-access-router',
  'mobile-streamer',
  'modem',
  'moh-server',
  'mse',
  'multi-fabric-server-switch',
  'multilayer-remote-switch',
  'multiswitch-device',
  'mux',
  'mxe',
  'nac-appliance',
  'nce-router',
  'nce',
  'netflow-router',
  'netranger',
  'netsonar',
  'network-management',
  'network-security',
  'nexus-1000',
  'nexus-2000-fabric-extender',
  'nexus-5000',
  'nexus-7000',
  'octel',
  'ons15500',
  'optical-amplifier',
  'optical-services-router',
  'optical-transport',
  'pad-x28',
  'pad',
  'page-icon',
  'pbx-switch',
  'pbx',
  'pc-adapter-card',
  'pc-man',
  'pc-routercard',
  'pc-sosftware',
  'pc-video',
  'pc',
  'pda',
  'phone-fax',
  'phone',
  'pix-firewall',
  'pmc',
  'printer',
  'programmable-switch',
  'protocol-translator',
  'pxf',
  'radio-tower',
  'ratemux',
  'relational-database',
  'repeater',
  'rf-modem',
  'route-switch-processor',
  'router-firewall',
  'router-in-building',
  'router-with-silicon-switch',
  'router',
  'rpsrps',
  'running-man',
  'safeharbor-icon',
  'sattelite-dish',
  'sattelite',
  'scanner',
  'server-switch',
  'server-with-router',
  'service-control',
  'service-module',
  'service-router',
  'services',
  'set-top-box',
  'simultlayer-switch',
  'sip-proxy-server',
  'sitting-woman',
  'small-business',
  'small-hub',
  'softphone',
  'softswitch-pgw-mgc',
  'software-based-server',
  'space-router',
  'speaker',
  'ssc',
  'ssl-terminator',
  'standard-host',
  'standing-man',
  'standing-woman',
  'stb',
  'storage-router',
  'storge-server',
  'stp',
  'streamer',
  'sun-workstation',
  'supercomputer',
  'svx',
  'system-controller',
  'tablet',
  'tape-array',
  'tdm-router',
  'telecommuter-house-pc',
  'telecommuter-house',
  'telecommuter-icon',
  'token',
  'tp-mcu',
  'transpath',
  'truck',
  'turret',
  'tv',
  'ubr910',
  'umg-series',
  'unity-server',
  'universal-gateway',
  'university',
  'upc',
  'ups',
  'vault',
  'video-camera',
  'vip',
  'virtual-layer-switch',
  'virtual-switch-controller-vsc3',
  'voice-atm-switch',
  'voice-commserver',
  'voice-router',
  'voice-switch',
  'vpn-concentrator',
  'vpn-gateway',
  'vss',
  'wae',
  'wavelength-router',
  'web-browser',
  'web-cluster',
  'wi-fi-tag',
  'wireless-location-appliance',
  'wireless-router',
  'wireless-transport',
  'wireless',
  'wirelss-bridge',
  'wism',
  'wlan-controller',
  'workgroup-director',
  'workgroup-switch',
  'workstation',
  'www-server',
];

export const NetworkingIcons = [
  'a-b-switch',
  'atm-switch-2',
  'atm-switch',
  'biometric-reader',
  'bridge',
  'building',
  'camera',
  'card-reader',
  'cellphone',
  'cloud',
  'comm-link',
  'copier',
  'diagnostic-device',
  'ethernet',
  'external-hard-drive',
  'external-media-drive',
  'fax-machine',
  'fiber-optic-2',
  'fiber-optic',
  'file-2',
  'file-server',
  'file',
  'firewall',
  'hub',
  'keyboard',
  'lock',
  'mainframe',
  'micro-webserver',
  'modem',
  'monitor',
  'mouse',
  'mpls-2',
  'mpls',
  'multi-function-machine',
  'patch-panel',
  'pbx',
  'pc',
  'phone',
  'plotter',
  'printer',
  'project-screen-2',
  'project-screen',
  'projector-2',
  'projector-3',
  'projector',
  'radio-tower',
  'relational-database',
  'repeater',
  'ring-network',
  'router',
  'satellite-dish',
  'satellite',
  'scanner',
  'server-2',
  'server',
  'smart-phone-2',
  'smart-phone',
  'standing-man',
  'standing-woman',
  'super-computer',
  'switch',
  'telephone',
  'ups',
  'user',
  'video-camera',
  'video-phone',
  'virtual-server',
  'voip-phone',
  'web-service',
  'wireless-access-point',
  'wireless-router',
  'working-person',
];

export const DatabaseIcons = [
  'aerospike',
  'airtable',
  'apache-cassandra',
  'apache-couchdb',
  'apache-hadoop',
  'apache-hbase',
  'aws-dynamodb',
  'aws-mobile-hub',
  'aws-rds-relational-database-service',
  'cloudera',
  'couchbase',
  'crateio',
  'dat',
  'database-labs',
  'doctrine',
  'flocker',
  'google-firebase',
  'ibm-cloudant',
  'ibm-compose',
  'influxdb',
  'kinto',
  'knexjs',
  'leveldb',
  'mariadb',
  'memsql',
  'mongodb-realm',
  'mongodb',
  'mysql',
  'neo4j',
  'nuodb',
  'oracle',
  'parse',
  'percona',
  'postgresql',
  'pouchdb',
  'prisma',
  'pumpkindb',
  'pusher',
  'redis',
  'redsmin',
  'rethinkdb',
  'riak',
  'rocksdb',
  'rxdb',
  'scaledrone',
  'sequelize',
  'sqldep',
  'sqlite',
];

export const ComputerIcons = [
  'crt-monitor',
  'data-pipe',
  'keyboard',
  'laptop-device',
  'lcd-monitor',
  'mouse',
  'pc',
  'pda',
  'slate',
  'tablet',
  'terminal',
  'virtual-pc',
];
